import React from 'react'
import Layout from 'components/layout'
import Meta from 'components/meta'
import { siteMetadata } from '../../../gatsby-config'
import Lines from 'components/lines'
import Dinamiza from 'components/colorDinamiza'
import Light from 'components/colorLight'
import style from 'scss/pages/posizioniAperte.module.scss'
import ItemPosition from 'components/itemPosition'
import FormContactCareers from "components/formContactCareers";

class FrontendDeveloper extends React.Component{
  render() {

    const { location, data } = this.props

    return(
      <div ref={this.page} className={style.page}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="Front-end Developer" description={'Entra a far parte del Team Dinamiza: la web agency a Venezia specializzata in ecommerce, applicazioni e siti web innovativi e di design.'}/>

          <Lines />

          <div className={`container-fluid ${style.page__content}`}>
            <div className={`row`}>
              <div className={`offset-md-2 col-md-2`}>
                <p className={`has-color-primary-negative ${style.page__content__claimLeft}`}><Light>Front-end Developer</Light></p>
              </div>
              <div className={`offset-md-1 col-md-7 no-padding ${style.page__content__fullScreenSlide}`}>
                <div>
                  <h3>Cerchiamo un web developer front-end</h3>

                  <p>Cerchiamo persone entusiaste del web con almeno 2 anni di esperienza comprovabile che si integrino
                    facilmente nel nostro team, contribuiscano a sviluppare idee aziendali e ottimizzare quelle dei
                    clienti. Nel caso specifico richiediamo affidabilità e autonomia nella gestione dei progetti,
                    ottime capacità organizzative e di time management.</p>

                  <p>Posti Vacanti: 1</p>

                  <p>Cosa farai all'interno del Team:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Sviluppo di front-end per web applications</li>
                    <li>Proporre soluzioni innovative in ambito front end</li>
                  </ul>

                  <p>Competenze richieste:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Ottima conoscenza HTML e CSS</li>
                    <li>Ottima conoscenza di almeno un framework CSS</li>
                    <li>Conoscenza di SASS</li>
                    <li>Conoscenza di almeno un framework front-end tra React, vue.js</li>
                    <li>Conoscenza di sistemi di versioning (git)</li>
                    <li>Ottima conoscenza Javascript/JQuery</li>
                    <li>Familiarità con l’ambiente Unix/Linux e lo stack LAMP</li>
                    <li>Buona conoscenza della lingua inglese scritta</li>
                  </ul>
                  <p>Gradita:</p>
                  <ul className="lista_generica lista_competenze">
                    <li>Familiarità con framework Symfony o similare</li>
                    <li>Conoscenza dell'ambiente Figma</li>
                  </ul>

                  <p>Contratto di lavoro: Valutiamo sia assunzioni che collaborazioni in PIVA</p>
                  
                  <p>Modalità di lavoro: Ibrido o remoto</p>

                  <h4 className={'title'}>Inviaci il curriculum</h4>
                  <FormContactCareers position={'RIF.56 - Front end Developer'} />

                </div>
              </div>
            </div>
            <div className={`offset-md-5 col-md-7 no-padding ${style.page__content__fullScreenSlide__arrow}`}></div>
          </div>
        </Layout>
      </div>
    )
  }
}

export default FrontendDeveloper
